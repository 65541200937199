import React from 'react'


import Lottie from "react-lottie";
import styled from "@emotion/styled";

const MobilePlayer = styled.div`
	height: 100%;
	width: 100%;
	pointer-events: none;

	@media (min-width: 768px) {
		display: none;
	}
`

const DesktopPlayer = styled.div`
	display: none;
	pointer-events: none;

	@media (min-width: 768px) {
		display: initial;
	}
`

const LottiePlayer = ({ mobileMediaGridMediaFile, desktopMediaGridMediaFile}) => {

	const defaultDesktopOptions = {
		loop: true,
		autoplay: true,
		animationData: desktopMediaGridMediaFile,
		rendererSettings: {
			preserveAspectRatio: 'xMidYMid slice'
		}
	};

	const defaultMobileOptions = mobileMediaGridMediaFile &&  {
		loop: true,
		autoplay: true,
		animationData: mobileMediaGridMediaFile,
		rendererSettings: {
			preserveAspectRatio: 'xMidYMid slice'
		}
	};

	return (
		<React.Fragment>
			{mobileMediaGridMediaFile
			? (
				<React.Fragment>
					<MobilePlayer>
						<Lottie options={defaultMobileOptions} />
					</MobilePlayer>
					<DesktopPlayer>
						<Lottie options={defaultDesktopOptions} />
					</DesktopPlayer>
				</React.Fragment>
			)
			: (
				<Lottie options={defaultDesktopOptions} />
			)}
		</React.Fragment>
	)
}

export default LottiePlayer;