import React from 'react';
import Lottie from "react-lottie";

import MuteBuild from './assets/Volume_Mute_Build.json'
import VolumeBuild from './assets/Volume_High_Build.json'

export default ({ isMuted }) => {

	const defaultOptions = {
		loop: false,
		autoplay: true,
		animationData: isMuted ? MuteBuild : VolumeBuild,
		rendererSettings: {
			preserveAspectRatio: 'xMidYMid slice'
		}
	};

	return (
		<React.Fragment>
			<div className='lottie--mobile'>
				<Lottie
					height={12}
					width={12}
					options={defaultOptions} />
			</div>
			<div className='lottie--desktop'>
				<Lottie
					height={22}
					width={22}
					options={defaultOptions} />
			</div>
		</React.Fragment>
	)
}
