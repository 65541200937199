//
// === Note ===
// This component is written in JSX instead of TSX in an effort to save time/money for Hobbes. (I'm charging them hourly)
//

import React from 'react'
import MediaModuleGridItem from './MediaModuleGridItem';

import styled from "@emotion/styled";
import cx from 'classnames'

const MediaModuleContainer = styled.section`
	display: grid;
	grid-template-columns: repeat(${props => (props.mobileColumnCount ? props.mobileColumnCount : '1')}, minmax(0, 1fr));
	grid-template-rows: 1fr;
	grid-column-gap: 20px;
	grid-row-gap: 20px;
	width: 100vw;
	padding: 1.25rem;

	&.padding {
		padding: 1.25rem 10px;
	}

	&.contained {
		margin-left: auto;
		margin-right: auto;
		@media (min-width: 667px) {
			width: calc(100vw - 190px);
		}
	}

	@media (min-width: 768px) {
		grid-template-columns: repeat(12, minmax(0, 1fr));
	}
`

const MediaModule = ({ padding, contained, list = [], mobileColumnCount }) => (
	<MediaModuleContainer mobileColumnCount={mobileColumnCount} className={cx('', { contained, padding })}>
		{list.map((item, index) => {
			const key = item.desktopMediaGridMediaFile.split('.com/')[1]
			return (
				<MediaModuleGridItem index={index} key={key} padding={padding} mediaOrderDesktop={item.mediaOrderDesktop} mediaOrderMobile={item.mediaOrderMobile} {...item} />
			)
		})}
	</MediaModuleContainer>
)


export default MediaModule