import React from 'react';
import Lottie from "react-lottie";

import MaximizeBuild from './assets/Maximize_Build.json'
import MinimizeBuild from './assets/Minimize_Build.json'

export default ({ isFullScreen }) => {

	const defaultOptions = {
		loop: false,
		autoplay: true,
		animationData: isFullScreen ? MinimizeBuild : MaximizeBuild,
		rendererSettings: {
			preserveAspectRatio: 'xMidYMid slice'
		}
	};

	return (
		<React.Fragment>
			{/* <div className='lottie--mobile'>
				<Lottie
					height={12}
					width={12}
					options={defaultOptions} />
			</div> */}
			<div className='lottie--desktop'>
				<Lottie
					height={22}
					width={18}
					options={defaultOptions} />
			</div>
		</React.Fragment>
	)
}
