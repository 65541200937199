import React, { useState, useEffect, useRef } from 'react';
import ReactHowler from 'react-howler'
import Lottie from "lottie-react";
import InputRange from "react-input-range";
import AnimatedMuteUnmute from "../HobVideo/Controls/AnimatedMuteUnmute";
import AnimatedPlayPause from "../HobVideo/Controls/AnimatedPlayPause";
import axios from "axios";

import useInterval from '../../hooks/useInterval';
import { useInView } from "react-intersection-observer";

import breakpoints from "../../breakpoints";
import styled from "@emotion/styled";
import cx from 'classnames'

const Container = styled.div`
	position: relative;
	width: 100%;
	height: 100%;
	background-color: var(--hob-color--light);
	margin: 1.25rem 0;

	&.padding {
		padding: 1.25rem;
	}

	&.contained {
		margin-left: auto;
		margin-right: auto;
		@media (min-width: 768px) {
			width: 66.66vw;
		}

		@media (min-width: 1024px) {
			width: calc(66.6vw - 120px);
		}
	}

	&.playing {
		.lottie-controls {
			opacity: 0;
		}

		&:hover {
			.lottie-controls {
				opacity: 1;
			}
		}
		&.hover {
			.lottie-controls {
				opacity: 1;
			}
		}
	}
`

const Controls = styled.div`
  display: flex;
  align-items: center;
  transition: opacity 300ms;
  height: 50px;
  background-color: var(--hob-color--light);
  padding: 0 25px;
  position: absolute;
  bottom: 80px;
  left: calc(16.66% + 20px);
  right: calc(16.66% + 20px);

  @media (min-width: 1600px) {
    left: 50%;
    right: auto;
    transform: translateX(-50%);
    width: 764px;
  }

  ${breakpoints.mobile} {
    padding: 0 10px;
    left: 34px;
    right: 34px;
    bottom: 24px;
    transform: translateX(0%);
    width: auto;
    height: 24px;
  }
`;

const TimeAndProgress = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0 20px;

  ${breakpoints.mobile} {
    padding: 0 10px;
  }
`;



const ProgressContainer = styled.div`
	position: relative;
	width: 100%;
	display: flex;
	align-items: center;
	padding: 0 10px;

	.input-range {
		display: flex;
		align-items: center;
	  }

	  .input-range__track--background {
		margin-top: 0;
		transform: translateY(-50%);

		&:after {
		  content: "";
		  background-color: #ffffff;
		  height: 2px;
		  top: 50%;
		  display: block;
		  transform: translateY(-3px);
		  position: relative;
		  z-index: -1;
		}
	  }

	  .input-range__label--min,
	  .input-range__label--max,
	  .input-range__label-container {
		opacity: 0;
		display: none;
		height: 0;
	  }

	  .input-range__track {
		height: 12px;
		background-color: transparent;
	  }
	  .input-range__track--active {
		background-color: #000000;
		height: 2px;
		top: 50%;
		transform: translateY(-50%);
	  }
	  .input-range__slider {
		border-radius: 0%;
		background: transparent;
		border: none;
		height: 12px;
		width: 12px;
		margin-left: 0px;
		margin-top: 0px;
		transform: translate(-50%, -11px);
		padding: 15px;
		position: relative;

		${breakpoints.mobile} {
		  transform: translate(-50%, -10px);
		}

		&:after {
		  content: "";
		  background-color: #000000;
		  width: 2px;
		  height: 12px;
		  position: absolute;
		  top: 50%;
		  transform: translateY(-50%);
		  ${breakpoints.mobile} {
			height: 8px;
			transform: translateY(-60%);
		  }
		}
	  }
`;


const ControlButton = styled.button`
  padding: 0;
  border: 0;
  .lottie--mobile {
    display: none;
  }
  .lottie--desktop {
    display: block;
  }

  ${breakpoints.mobile} {
    .lottie--mobile {
      margin-top: -3px;
      display: block;
    }
    .lottie--desktop {
      display: none;
    }
  }
`;


export const HobbesLottie = ({autoPlay = false, loop = false, contained, audio, lottieFile}) => {

	const [isPlaying, setIsPlaying] = useState(!!autoPlay)
	const [isHovering, setIsHovering] = useState(false)
	const [isMuted, setIsMuted] = useState(true)
	const [isReady, setIsReady] = useState(false)
	const [progress, setProgress] = useState(0)
	const [animationFile, setAnimationFile] = useState(null)
	const audioRef = useRef()
	const lottieRef = useRef()

	const { ref, inView, entry } = useInView({
		threshold: 0
	});



	useInterval(
		() => {
			const currentProgress = ((audioRef.current.seek()/audioRef.current.duration())*100).toFixed(2)
			setProgress(currentProgress)
		},
		isPlaying ? 100 : null,
	)

	const onSeek = (value) => {
		setIsPlaying(false)
		const percentage = value / 100

		const lottieDuration = lottieRef.current.getDuration(true)
		const audioDuration = audioRef.current.duration()

		const newAudioValue = audioDuration * percentage
		const newLottieValue = lottieDuration * percentage

		audioRef.current.seek(newAudioValue)
		lottieRef.current.goToAndPlay(newLottieValue, true)
		setIsPlaying(true)
	}

	useEffect(() => {
		const requestFiles = async () => {
			const lottieRequest = await axios.get(lottieFile)

			setAnimationFile(lottieRequest.data)
			setIsReady(true)
		}

		requestFiles()
	}, []);

	useEffect(() => {
		if (!isReady) return;
		!!isPlaying ? lottieRef.current.play() : lottieRef.current.pause()

	}, [isPlaying]);

	useEffect(() => {
		if (!lottieRef.current || !audioRef.current) return

		if (inView) {
			lottieRef.current.play()
			audioRef.current.play()
			setIsPlaying(true)
		} else {
			lottieRef.current.pause()
			audioRef.current.pause()
			setIsPlaying(false)
		}
	}, [inView]);

	useEffect(() => {
		return () => {
			lottieRef.current.destroy()
			audioRef.current.unload()
		};
	}, []);

	return (
		<Container
			onMouseEnter={() => setIsHovering(true)}
			onMouseLeave={() => setIsHovering(false)}
			ref={ref}
			className={cx('', {
				contained,
				playing: isPlaying,
				hover: isHovering
			})}>
				{isReady && (
					<React.Fragment>
						<Lottie
							animationData={animationFile}
							lottieRef={lottieRef}
							autoPlay={autoPlay}
							loop={loop}
							onComplete={() => {
								setIsPlaying(false)
								lottieRef.current.stop()
								setProgress(0)
							}}
							onClick={() => setIsPlaying(!isPlaying)}
							/>
						<ReactHowler
							src={audio}
							playing={isPlaying}
							onEnd={() => {
								setIsPlaying(false)
								lottieRef.current.stop()
								setProgress(0)
							}}
							loop={loop}
							mute={isMuted}
							ref={audioRef}
							html5={true}
							format={['webm', 'mp3', 'wav']}
						/>
						<Controls className='lottie-controls'>
							<ControlButton onClick={() => setIsPlaying(!isPlaying)}>
								<AnimatedPlayPause isPlaying={isPlaying} />
							</ControlButton>
							<TimeAndProgress>
								<ProgressContainer>
									<InputRange
										maxValue={100}
										minValue={0}
										value={progress}
										onChange={onSeek}
									/>
								</ProgressContainer>
							</TimeAndProgress>
							<ControlButton onClick={() => setIsMuted(!isMuted)}>
								<AnimatedMuteUnmute isMuted={isMuted} />
							</ControlButton>
						</Controls>
					</React.Fragment>
				)}
		</Container>
	)
}