//
// === Note ===
// This component is written in JSX instead of TSX in an effort to save time/money for Hobbes. (I'm charging them hourly)
//

import React, { useEffect, useState } from 'react'
import LottiePlayer from './LottiePlayer'

import axios from "axios";
import styled from "@emotion/styled";
import cx from 'classnames'

const GridItem = styled.article`
	grid-column: span 1 / span 1;

	&.no-margin {
		margin-top: 0;
	}

	&.hide-mobile {
		display: none;
	}

	@media (min-width: 768px) {
		margin-top: 0;

		&.hide-mobile {
			display: initial;
		}

		&.full-width {
			grid-column: span 12 / span 12;
		}
		&.one-half {
			grid-column: span 6 / span 6;
		}
		&.one-third {
			grid-column: span 4 / span 4;
		}
		&.one-fourth {
			grid-column: span 3 / span 3;
		}
		&.one-sixth {
			grid-column: span 2 / span 2;
		}
		&.two-third {
			grid-column: span 8 / span 8;
		}
		&.three-quarters {
			grid-column: span 9 / span 9;
		}
	}
`

const MobileOnly = styled.div`
	@media (min-width: 768px) {
		display: none;
	}
`

const DesktopOnly = styled.div`
	display: none;
	@media (min-width: 768px) {
		display: initial;
	}
`

const Image = styled.img`
	object-fit: cover;
	width: 100%;
	height: 100%;
	display: block;
`

const MediaModuleGridItem = ({
	padding,
	index,
	desktopMediaGridMediaFile,
	mediaGridSize,
	mediaOrderDesktop,
	mediaOrderMobile,
	mobileMediaGridMediaFile,
	hideMobile,
	hideControls = false
}) => {

	const [isDesktopLottie, setIsDesktopLottie] = useState(null)
	const [isMobileLottie, setIsMobileLottie] = useState(null)

	useEffect(() => {
		axios.get(desktopMediaGridMediaFile)
			.then(({ data }) => {
				setIsDesktopLottie(typeof data === 'object' ? data : false)
			});
		if (mobileMediaGridMediaFile) {
			axios.get(mobileMediaGridMediaFile)
				.then(({ data }) => {
					setIsMobileLottie(typeof data === 'object' ? data : false)
				});
		}
	}, [desktopMediaGridMediaFile, mobileMediaGridMediaFile]);

	return (
		<GridItem

			mediaOrderDesktop={mediaOrderDesktop ||  index + 1}
			mediaOrderMobile={mediaOrderMobile ||  index + 1}
			className={cx(`${mediaGridSize}`, {
				'no-margin': index === 0,
				'hide-mobile': hideMobile
			})}>
			{mobileMediaGridMediaFile && (
				<React.Fragment>
					<MobileOnly>
							{isMobileLottie
								? (
									<LottiePlayer
										mobileMediaGridMediaFile={isMobileLottie}
										desktopMediaGridMediaFile={null}
									/>
								)
								: (
									<Image src={mobileMediaGridMediaFile} />
								)
							}

					</MobileOnly>
					<DesktopOnly>
							{isDesktopLottie
								? (
									<LottiePlayer
										mobileMediaGridMediaFile={null}
										desktopMediaGridMediaFile={isDesktopLottie}
									/>
								)
								: (
									<Image src={desktopMediaGridMediaFile} />
								)
							}

					</DesktopOnly>
				</React.Fragment>
			)}
			{!mobileMediaGridMediaFile && (
				<React.Fragment>
					{isDesktopLottie
						? (
							<LottiePlayer
								mobileMediaGridMediaFile={null}
								desktopMediaGridMediaFile={isDesktopLottie}
							/>
						)
						: (
							<Image src={desktopMediaGridMediaFile}  />
						)
					}
				</React.Fragment>
			)}
		</GridItem>
	)
}

export default MediaModuleGridItem