import { HobTypography } from "@components/HobTypography";
import { MediaType } from "@components/Project";
import styled from "@emotion/styled";
import React, {
  HTMLProps,
  ReactNode,
  useEffect,
  useReducer,
  useRef
} from "react";
import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";
import { useInView } from "react-intersection-observer";
import breakpoints from "../../breakpoints";
import AnimatedFullScreen from "./Controls/AnimatedFullScreen";
import AnimatedMuteUnmute from "./Controls/AnimatedMuteUnmute";
import AnimatedPlayPause from "./Controls/AnimatedPlayPause";

import cx from 'classnames'

export interface IVideoProps {
	source?: string
	mimeType?: string
	showStop?: boolean
	showMute?: boolean
	showFullscreen?: boolean
	children: ReactNode
	loop?: boolean
	hideControls?: boolean
}

const Container = styled.div`
	position: relative;
	width: 100%;
	height: 100%;
	background-color: var(--hob-color--light);

	.hob-video__ref {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		pointer-events: none;
	}

	&.hob-video--playing {
		.hob-video__controls {
			opacity: 0;
		}
	}
	&.hob-video--playing:hover {
		.hob-video__controls {
			opacity: 1;
		}
	}

	video {
		width: 100%;
		height: 100%;
	}

	button {
		&:focus {
			border: none;
			outline: none;
		}
	}

	progress[value] {
		-webkit-appearance: none;
		appearance: none;
		-moz-appearance: none;
		height: 2px;

		/* For IE10 */
		color: #ffffff;

		&::-webkit-progress-bar {
			background-color: #ffffff;
		}

		&::-webkit-progress-value {
			background-color: #000000;
		}
	}

	.input-range {
		display: flex;
		align-items: center;
	}

	.input-range__track--background {
		margin-top: 0;
		transform: translateY(-50%);

		&:after {
			content: '';
			background-color: #ffffff;
			height: 2px;
			top: 50%;
			display: block;
			transform: translateY(-3px);
			position: relative;
			z-index: -1;
		}
	}

	.input-range__label--min,
	.input-range__label--max,
	.input-range__label-container {
		opacity: 0;
		display: none;
		height: 0;
	}

	.input-range__track {
		height: 12px;
		background-color: transparent;
	}
	.input-range__track--active {
		background-color: #000000;
		height: 2px;
		top: 50%;
		transform: translateY(-50%);
	}
	.input-range__slider {
		border-radius: 0%;
		background: transparent;
		border: none;
		height: 12px;
		width: 12px;
		margin-left: 0px;
		margin-top: 0px;
		transform: translate(-50%, -11px);
		padding: 15px;
		position: relative;

		${breakpoints.mobile} {
			transform: translate(-50%, -10px);
		}

		&:after {
			content: '';
			background-color: #000000;
			width: 2px;
			height: 12px;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			${breakpoints.mobile} {
				height: 8px;
				transform: translateY(-60%);
			}
		}
	}

	&:fullscreen {
		background-color: #000000;

		.hob-video__controls {
			position: fixed;
			bottom: 0;
			left: 0;
			right: 0;
			opacity: 1;
			transform: translateX(0%);
			max-width: 100%;
			width: 100%;
		}
	}
`

const Controls = styled.div`
	display: flex;
	align-items: center;
	transition: opacity 300ms;
	height: 50px;
	background-color: var(--hob-color--light);
	padding: 0 25px;
	position: absolute;
	bottom: 80px;
	left: calc(16.66% + 20px);
	right: calc(16.66% + 20px);

	@media (min-width: 1600px) {
		left: 50%;
		right: auto;
		transform: translateX(-50%);
		width: 764px;
	}

	${breakpoints.mobile} {
		padding: 0 10px;
		left: 34px;
		right: 34px;
		bottom: 24px;
		transform: translateX(0%);
		width: auto;
		height: 24px;
	}

	&.hidden {
		display: none;
		pointer-events: none;
		visibility: hidden;
	}
`

const TimeAndProgress = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	padding: 0 20px;

	${breakpoints.mobile} {
		padding: 0 10px;
	}
`

const CurrentTime = styled(HobTypography)`
	z-index: 2;
	font-size: 18px;
	width: 6ch;

	${breakpoints.mobile} {
		font-size: 8px;
	}

	&.right {
		text-align: center;
	}
`

const ProgressContainer = styled.div`
	position: relative;
	width: 100%;
	display: flex;
	align-items: center;
	padding: 0 10px;
`

const Video = styled.video`
	&:hover {
		cursor: pointer;
	}

  &.hideControls {
    &:hover {
      cursor: auto;
    }
  }
`

const ControlButton = styled.button`
	padding: 0;
	border: 0;
	.lottie--mobile {
		display: none;
	}
	.lottie--desktop {
		display: block;
	}

	${breakpoints.mobile} {
		.lottie--mobile {
			margin-top: -3px;
			display: block;
		}
		.lottie--desktop {
			display: none;
		}
	}
`

const AudioButton = styled(ControlButton)`
	margin-right: 16px;

	${breakpoints.mobile} {
		margin-right: 5px;
	}
`

interface State {
	currentTime: number
	duration: number
	fullscreenEnabled: boolean
	isFullscreen: boolean
	paused: boolean
	volume: number
	isMuted: boolean
}

const initialState: State = {
	currentTime: 0,
	duration: 0,
	fullscreenEnabled: true,
	isFullscreen: false,
	isMuted: true,
	paused: true,
	volume: 0,
}

type Action =
	| { type: 'SET_CURRENT_TIME'; payload: number }
	| { type: 'SET_DURATION'; payload: number }
	| { type: 'SET_FULL_SCREEN_ENABLED'; payload: boolean }
	| { type: 'SET_FULL_SCREEN'; payload: boolean }
	| { type: 'SET_PAUSED'; payload: boolean }
	| { type: 'SET_MUTE'; payload: boolean }

const reducer = (state: State, action: Action): State => {
	switch (action.type) {
		case 'SET_FULL_SCREEN_ENABLED':
			return {
				...state,
				fullscreenEnabled: action.payload,
			}

		case 'SET_FULL_SCREEN':
			return {
				...state,
				isFullscreen: action.payload,
			}

		case 'SET_DURATION':
			return {
				...state,
				duration: action.payload,
			}

		case 'SET_CURRENT_TIME':
			return {
				...state,
				currentTime: action.payload,
			}

		case 'SET_PAUSED':
			return {
				...state,
				paused: action.payload,
			}

		case 'SET_MUTE':
			return {
				...state,
				isMuted: action.payload,
			}

		default:
			return state
	}
}

export const HobVideo: React.FC<IVideoProps & HTMLProps<HTMLVideoElement>> = ({ source, mimeType, showFullscreen = false, showStop = false, loop = true, hideControls = false, poster = false, children }) => {
	const [state, dispatch] = useReducer(reducer, initialState)
	const containerRef = useRef<HTMLDivElement>(null)
	const videoRef = useRef<HTMLVideoElement>(null)
	const { ref, inView, entry } = useInView({
		/* Optional options */
		threshold: 0,
	})

	useEffect(() => {
		const video = videoRef.current

		if (inView) {
			dispatch({ type: 'SET_PAUSED', payload: false })
			video.play()
		} else {
			dispatch({ type: 'SET_PAUSED', payload: true })
			video.pause()
		}
	}, [inView])

	const withVideo = (fn: (v: HTMLVideoElement) => void) => (): void => {
		const video = videoRef.current
		if (!video) {
			return
		}
		return fn(video)
	}

	type VideoControl = (v: HTMLVideoElement) => void

	const playPause: VideoControl = video => {
    if (hideControls) return;

		if (video.paused || video.ended) {
			dispatch({ type: 'SET_PAUSED', payload: false })
			video.play()
		} else {
			dispatch({ type: 'SET_PAUSED', payload: true })
			video.pause()
		}
	}

	const stop: VideoControl = video => {
		video.pause()
		video.currentTime = 0
		dispatch({ type: 'SET_CURRENT_TIME', payload: 0 })
		dispatch({ type: 'SET_PAUSED', payload: true })
	}

	const mute: VideoControl = video => {
		video.muted = !video.muted
		dispatch({ type: 'SET_MUTE', payload: !state.isMuted })
	}

	// const volumeUp: VideoControl = video => {
	//   const currentVolume = Math.floor(video.volume * 10) / 10;
	//   if (currentVolume < 1) {
	//     const newVolume = video.volume + 0.1;
	//     dispatch({ type: "SET_VOLUME", payload: newVolume });
	//     video.volume = newVolume;
	//   }
	// };

	// const volumeDown: VideoControl = video => {
	//   const currentVolume = Math.floor(video.volume * 10) / 10;
	//   if (currentVolume > 0) {
	//     const newVolume = video.volume - 0.1;
	//     dispatch({ type: "SET_VOLUME", payload: newVolume });
	//     video.volume = newVolume;
	//   }
	// };

	const onMetaLoad: VideoControl = video => {
		dispatch({ type: 'SET_DURATION', payload: video.duration })
		dispatch({ type: 'SET_VOLUME', payload: video.volume })
	}

	const updateTime: VideoControl = video => {
		if (state.duration === 0) {
			dispatch({ type: 'SET_DURATION', payload: video.duration })
		}

		dispatch({ type: 'SET_CURRENT_TIME', payload: video.currentTime })
	}

	const handleScrubTime = value => {
		const { current: videoContainer } = videoRef

		dispatch({ type: 'SET_CURRENT_TIME', payload: value })
		if (videoContainer) {
			videoContainer.currentTime = value
		}
	}

	const setFullscreen: VideoControl = () => {
		const { current: videoContainer } = containerRef

		const setIsFullscreen = (b: boolean) => {
			dispatch({ type: 'SET_FULL_SCREEN', payload: b })
		}

		// const videoElement = videoContainer?.querySelector('.hob-video');

		if (state.isFullscreen) {
			// videoElement.setAttribute('playsinline', '');
			// videoElement.setAttribute('webkit-playsinline', '');

			if (document.exitFullscreen) {
				document.exitFullscreen()
			} else if (document.mozCancelFullScreen) {
				document.mozCancelFullScreen()
			} else if (document.webkitCancelFullScreen) {
				document.webkitCancelFullScreen()
			} else if (document.msExitFullscreen) {
				document.msExitFullscreen()
			}
			setIsFullscreen(false)
		} else {
			if (videoContainer) {
				// videoElement.removeAttribute('playsinline');
				// videoElement.removeAttribute('webkit-playsinline');
				// videoElement.play();

				if (videoContainer.requestFullscreen) {
					videoContainer.requestFullscreen()
				} else if (videoContainer.mozRequestFullScreen) {
					videoContainer.mozRequestFullScreen()
				} else if (videoContainer.webkitRequestFullScreen) {
					videoContainer.webkitRequestFullScreen()
				} else if (videoContainer.msRequestFullscreen) {
					videoContainer.msRequestFullscreen()
				}
				setIsFullscreen(true)
			}
		}
	}

	useEffect(() => {
		const exitHandler = () => {
			if (!document.fullscreenElement && !document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement) {
				const setIsFullscreen = (b: boolean) => {
					dispatch({ type: 'SET_FULL_SCREEN', payload: b })
				}
				setIsFullscreen(false)
			}
		}

		document.addEventListener('fullscreenchange', exitHandler)
		document.addEventListener('webkitfullscreenchange', exitHandler)
		document.addEventListener('mozfullscreenchange', exitHandler)
		document.addEventListener('MSFullscreenChange', exitHandler)

		return () => {
			document.removeEventListener('fullscreenchange', exitHandler)
			document.removeEventListener('webkitfullscreenchange', exitHandler)
			document.removeEventListener('mozfullscreenchange', exitHandler)
			document.removeEventListener('MSFullscreenChange', exitHandler)
		}
	}, [state.isFullscreen])

	const currentTime = () => {
		const padLeft = (str: string) => str.replace(/^(\d)$/, '0$1')
		const minutes = String(parseInt(state.currentTime / 60, 10))
		const seconds = String(parseInt(state.currentTime % 60, 10))

		return `${padLeft(minutes)}:${padLeft(seconds)}`
	}

	const videoDuration = () => {
		const padLeft = (str: string) => str.replace(/^(\d)$/, '0$1')
		const minutes = String(parseInt(state.duration / 60, 10))
		const seconds = String(parseInt(state.duration % 60, 10))

		return `${padLeft(minutes)}:${padLeft(seconds)}`
	}

	let videoToRender = (
		<Video
			preload='auto'
			className={cx('hob-video', {
        'hideControls': hideControls
      })}
			ref={videoRef}
			onLoadedMetadata={withVideo(onMetaLoad)}
			onTimeUpdate={withVideo(updateTime)}
			onEnded={withVideo(stop)}
			onClick={withVideo(playPause)}
			loop={loop}
			muted={true}
			playsInline={true}
			poster={poster ? poster : undefined}>
			{source ? <source src={source} type={mimeType} /> : children}
			<p>
				Your browser doesn't support HTML5 video.
				<a href='videos/mikethefrog.mp4'>Download</a> the video instead.
			</p>
		</Video>
	)

	if (children.props.type === MediaType.QUICKTIME) {
		videoToRender = (
			<Video
				preload={'auto'}
				className={cx('hob-video', {
          'hideControls': hideControls
        })}
				ref={videoRef}
				onLoadedMetadata={withVideo(onMetaLoad)}
				onTimeUpdate={withVideo(updateTime)}
				onEnded={withVideo(stop)}
				onClick={withVideo(playPause)}
				loop={loop}
				src={children.props.src}
				muted={true}
				playsInline={true}
				poster={poster ? poster : undefined}
			/>
		)
	}

	return (
		<Container ref={containerRef} className={`hob-video hob-video--${state.paused ? 'paused' : 'playing'}`}>
			<div className='hob-video__ref' ref={ref} />
			{videoToRender}
			<Controls
				className={cx('hob-video__controls', {
					hidden: hideControls,
				})}>
				<ControlButton onClick={withVideo(playPause)}>
					<AnimatedPlayPause isPlaying={!state.paused} />
				</ControlButton>

				<TimeAndProgress>
					<CurrentTime variant='body1'>{currentTime()}</CurrentTime>
					<ProgressContainer>
						<InputRange maxValue={state.duration} minValue={0} value={state.currentTime} onChange={handleScrubTime} />
					</ProgressContainer>

					<CurrentTime className='right' variant='body1'>
						{videoDuration()}
					</CurrentTime>
				</TimeAndProgress>

				<AudioButton onClick={withVideo(mute)}>
					<AnimatedMuteUnmute isMuted={state.isMuted} />
				</AudioButton>

				{state.fullscreenEnabled && (
					<ControlButton onClick={withVideo(setFullscreen)}>
						<AnimatedFullScreen isFullScreen={state.isFullscreen} />
					</ControlButton>
				)}
			</Controls>
		</Container>
	)
}
