import React from 'react';
import Lottie from "react-lottie";

import PlayBuild from './assets/play_filled_build.json'
import PauseBuild from './assets/pause_filled_build.json'

export default ({isPlaying}) => {
	const defaultOptions = {
		loop: false,
		autoplay: true,
		animationData: isPlaying ? PauseBuild : PlayBuild,
		rendererSettings: {
			preserveAspectRatio: 'xMidYMid slice'
		}
	};

	return (
		<React.Fragment>
			<div className='lottie--mobile'>
				<Lottie
					height={12}
					width={12}
					options={defaultOptions} />

			</div>
			<div className='lottie--desktop'>
				<Lottie
					height={22}
					width={18}
					options={defaultOptions} />
			</div>
		</React.Fragment>
	)
}
